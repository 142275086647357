import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Badge,
  Container,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ShoppingCart, Mail, Phone, Smartphone } from "lucide-react";
import { useCart } from "../Context";
import { useNavigate } from "react-router-dom";

// Attempt to import the logo
let logoImage;
try {
  logoImage = require("./logo-dark.png");
} catch (error) {
  console.warn("Failed to load logo image:", error);
  logoImage = null;
}

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: "#ffffff",
  color: "#117554",
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
}));

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px 20px",
});

const LogoContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  width: "350px",
  height: "100px",
});

const ScrollContainer = styled(Box)({
  overflow: "hidden",
  whiteSpace: "nowrap",
  flex: 1,
  margin: "0 20px",
});

const ScrollText = styled(Typography)({
  display: "inline-block",
  paddingLeft: "100%",
  animation: "scroll 20s linear infinite",
  "@keyframes scroll": {
    "0%": {
      transform: "translate(0, 0)",
    },
    "100%": {
      transform: "translate(-100%, 0)",
    },
  },
});

const ContactInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  color: "#117554",
}));

const ContactItem = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginBottom: "5px",
});

const Header = () => {
  const { cart } = useCart();
  const navigate = useNavigate();

  const handleCartClick = () => {
    navigate("/order");
  };

  return (
    <StyledAppBar position="static">
      <Container maxWidth="xl">
        <StyledToolbar>
          {/* Logo */}
          <LogoContainer>
            {logoImage ? (
              <img
                src={logoImage}
                alt="Logo"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            ) : (
              <Typography
                variant="h4"
                component="div"
                sx={{ fontWeight: "bold", color: "#117554" }}
              >
                First Diagnostic Lab
              </Typography>
            )}
          </LogoContainer>

          {/* Scrolling Text */}
          <ScrollContainer>
            <ScrollText
              variant="h6"
              sx={{ fontWeight: "bold", color: "#117554" }}
            >
              First Diagnostic Lab in Vijayawada
            </ScrollText>
          </ScrollContainer>

          {/* Right Side: Contact Info, Android Console, Cart */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ContactInfo>
              <ContactItem>
                <Mail size={20} style={{ marginRight: "8px" }} />
                <Typography variant="body1" sx={{ fontWeight: "medium" }}>
                  amritaadiagnostics@gmail.com
                </Typography>
              </ContactItem>
              <ContactItem>
                <Phone size={20} style={{ marginRight: "8px" }} />
                <Typography variant="body1" sx={{ fontWeight: "medium" }}>
                  9094 983 983
                </Typography>
              </ContactItem>
            </ContactInfo>
            <Box sx={{ display: "flex", alignItems: "center", ml: 3 }}>
              <Smartphone
                color="#117554"
                size={32}
                style={{ marginRight: "16px" }}
              />
              <Badge
                badgeContent={cart.length}
                color="secondary"
                sx={{
                  cursor: "pointer",
                  "& .MuiBadge-badge": {
                    fontSize: "14px",
                    height: "22px",
                    minWidth: "22px",
                  },
                }}
                onClick={handleCartClick}
              >
                <ShoppingCart color="#117554" size={32} />
              </Badge>
            </Box>
          </Box>
        </StyledToolbar>
      </Container>
    </StyledAppBar>
  );
};

export default Header;
