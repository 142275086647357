import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/system";

// Import icons
import MenuIcon from "@mui/icons-material/Menu";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SearchAutocomplete from "./SearchAutocomplete";
import { menuData, locations } from "./navbarData";

const theme = createTheme({
  palette: {
    primary: {
      main: "#16423C",
      light: "#6A9C89",
      dark: "#0D2A26",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#6A9C89",
      light: "#8BBAA9",
      dark: "#4A7C69",
      contrastText: "#FFFFFF",
    },
  },
});

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
}));

const NavButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  margin: theme.spacing(0, 0.5),
  padding: theme.spacing(1, 1),
  fontSize: "0.85rem",
  minWidth: "auto",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "4px",
    marginTop: "4px",
    minWidth: "180px",
    boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
    backgroundColor: theme.palette.primary.light,
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  fontSize: "0.85rem",
  padding: theme.spacing(0.75, 1.5),
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const Navbar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [location, setLocation] = useState("Vijaywada");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuOpen = (event, index) => {
    setAnchorEl(event.currentTarget);
    setOpenSubmenu(index);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenSubmenu(null);
  };

  const handleLocationChange = (event, loc) => {
    setLocation(loc);
    handleMenuClose();
  };

  const handleSubmenuClick = (index) => {
    setOpenSubmenu(openSubmenu === index ? null : index);
  };

  const drawer = (
    <Box sx={{ width: 250 }} role="presentation">
      <List>
        {menuData.map((item, index) => (
          <React.Fragment key={item.title}>
            <ListItem
              button
              component={RouterLink}
              to={item.path}
              onClick={() => handleSubmenuClick(index)}
            >
              <ListItemIcon>
                <item.icon color="secondary" />
              </ListItemIcon>
              <ListItemText primary={item.title} />
              {item.submenu.length > 0 &&
                (openSubmenu === index ? <ExpandLess /> : <ExpandMore />)}
            </ListItem>
            {item.submenu.length > 0 && (
              <Collapse in={openSubmenu === index} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.submenu.map((subItem, subIndex) => (
                    <ListItem
                      button
                      sx={{ pl: 4 }}
                      key={subIndex}
                      component={RouterLink}
                      to={subItem.path}
                    >
                      <ListItemText primary={subItem.title} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>
        <StyledAppBar position="static">
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2, display: { sm: "none" } }}
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              component={RouterLink}
              to="/"
              sx={{
                display: { xs: "none", sm: "block" },
                fontWeight: "bold",
                fontSize: "1rem",
                letterSpacing: 0.5,
                color: "inherit",
                textDecoration: "none",
                marginRight: 2,
              }}
            >
              Amritaa Diagnostics
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {menuData.map((item, index) => (
                <NavButton
                  key={item.title}
                  component={RouterLink}
                  to={item.path}
                  onClick={(event) => handleMenuOpen(event, index)}
                  startIcon={<item.icon sx={{ fontSize: 18 }} />}
                >
                  {item.title}
                </NavButton>
              ))}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  position: "relative",
                  borderRadius: 1,
                  bgcolor: "rgba(255, 255, 255, 0.15)",
                  "&:hover": { bgcolor: "rgba(255, 255, 255, 0.25)" },
                  mr: 1,
                  width: { xs: "100%", sm: "auto" },
                }}
              >
                <SearchAutocomplete />
              </Box>
              <NavButton
                color="inherit"
                startIcon={<LocationOnIcon sx={{ fontSize: 18 }} />}
                onClick={(event) => handleMenuOpen(event, -1)}
              >
                {location}
              </NavButton>
            </Box>
          </Toolbar>
        </StyledAppBar>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: 250,
              backgroundColor: "primary.main",
              color: "primary.contrastText",
            },
          }}
        >
          {drawer}
        </Drawer>
        {menuData.map((item, index) => (
          <StyledMenu
            key={item.title}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorEl) && openSubmenu === index}
            onClose={handleMenuClose}
          >
            {item.submenu.map((subItem, subIndex) => (
              <StyledMenuItem
                key={subIndex}
                component={RouterLink}
                to={subItem.path}
                onClick={handleMenuClose}
              >
                {subItem.title}
              </StyledMenuItem>
            ))}
          </StyledMenu>
        ))}
        <StyledMenu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl) && openSubmenu === -1}
          onClose={handleMenuClose}
        >
          {locations.map((loc) => (
            <StyledMenuItem
              key={loc}
              onClick={(event) => handleLocationChange(event, loc)}
            >
              {loc}
            </StyledMenuItem>
          ))}
        </StyledMenu>
      </Box>
    </ThemeProvider>
  );
};

export default Navbar;
