import React, { useState, useEffect } from "react";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import { Search } from "lucide-react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useCart } from "../Context";

const SearchAutocomplete = () => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { addToCart } = useCart();

  useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions([]);
      return undefined;
    }

    setLoading(true);

    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/department_test_data-9lwDJ0wVvlkMAQuqCVvFhazHr5JnI7.json"
        );
        const data = response.data;
        if (active) {
          const filteredOptions = Object.entries(data)
            .flatMap(([department, departmentData]) =>
              Object.entries(departmentData.tests).map(([testName, price]) => ({
                name: testName,
                price: price,
                department: department,
              }))
            )
            .filter(
              (test) =>
                test.name.toLowerCase().includes(inputValue.toLowerCase()) &&
                test.name !== "NaN" &&
                test.price !== 0
            );
          setOptions(filteredOptions);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      active = false;
    };
  }, [inputValue]);

  const handleOptionSelect = (event, value) => {
    if (value) {
      addToCart({
        id: `test-${value.name}`,
        name: value.name,
        price: value.price,
        department: value.department,
      });
      navigate("/order");
    }
  };

  return (
    <Autocomplete
      id="search-autocomplete"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={handleOptionSelect}
      options={options}
      getOptionLabel={(option) => option.name}
      loading={loading}
      renderOption={(props, option) => (
        <li {...props}>
          <div>
            <div>{option.name}</div>
            <div style={{ fontSize: "0.8em", color: "#666" }}>
              {option.department} - ₹{option.price}
            </div>
          </div>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search tests..."
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <Search size={20} color="#666" style={{ marginRight: "8px" }} />
            ),
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px",
              backgroundColor: "background.paper",
            },
          }}
        />
      )}
    />
  );
};

export default SearchAutocomplete;
