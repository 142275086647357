import React, { useState, Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { CartProvider } from "./Context";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

// Lazy load pages and components
const HomePage = lazy(() => import("./pages/HomePage"));
const AboutUs = lazy(() => import("./pages/AboutUs"));
const BookTest = lazy(() => import("./pages/BookTest"));
const TestPackages = lazy(() => import("./pages/TestPackages"));
const Locations = lazy(() => import("./pages/Locations"));
const ContactUs = lazy(() => import("./pages/ContactUs"));
const LoginSignup = lazy(() => import("./pages/LoginSignup"));

// Submenu Pages
const OurStory = lazy(() => import("./pages/about-us/OurStory"));
const MissionVision = lazy(() => import("./pages/about-us/MissionVision"));
const Accreditations = lazy(() => import("./pages/about-us/Accreditations"));
const TechnologyInfrastructure = lazy(() =>
  import("./pages/about-us/TechnologyInfrastructure")
);

const DiagnosticServices = lazy(() =>
  import("./pages/services/DiagnosticServices")
);
const PreventiveCheckups = lazy(() =>
  import("./pages/services/PreventiveCheckups")
);
const HomeSampleCollection = lazy(() =>
  import("./pages/services/HomeSampleCollection")
);

const ViewCart = lazy(() => import("./pages/book-test/ViewCart"));
const ManageBookings = lazy(() => import("./pages/book-test/ManageBookings"));

const HealthCheckupPackages = lazy(() =>
  import("./pages/test-packages/HealthCheckupPackages")
);
const SpecializedPackages = lazy(() =>
  import("./pages/test-packages/SpecializedPackages")
);
const CustomizedPackages = lazy(() =>
  import("./pages/test-packages/CustomizedPackages")
);

const ContactForm = lazy(() => import("./pages/contact-us/ContactForm"));
const FAQs = lazy(() => import("./pages/contact-us/FAQs"));

const CurrentOpenings = lazy(() => import("./pages/careers/CurrentOpenings"));
const Reports = lazy(() => import("./pages/Reports"));
const DOS = lazy(() => import("./pages/DOS"));
const Media = lazy(() => import("./pages/Media"));
const DepartmentCards = lazy(() => import("./pages/DepartmentCards"));
const CartPage = lazy(() => import("./pages/CartPage"));
const OrderPage = lazy(() => import("./pages/OrderPage"));
const OrderConfirmationPage = lazy(() =>
  import("./pages/OrderConfirmationPage")
);

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = (username, password) => {
    setIsLoggedIn(true);
    alert(`Welcome, ${username}!`);
  };

  return (
    <CartProvider>
      <>
        {/* Shared Header */}
        <Header
          onLogin={handleLogin}
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
        />

        {/* Shared Navbar */}
        <Navbar />

        {/* Routes with Suspense for Lazy Loading */}
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about/about" element={<AboutUs />} />
            <Route path="/about/our-story" element={<OurStory />} />
            <Route path="/about/mission-vision" element={<MissionVision />} />
            <Route path="/about/accreditations" element={<Accreditations />} />
            <Route
              path="/services/technology"
              element={<TechnologyInfrastructure />}
            />
            <Route
              path="/services/diagnostics"
              element={<DiagnosticServices />}
            />
            <Route
              path="/services/health-checkups"
              element={<PreventiveCheckups />}
            />
            <Route
              path="/services/home-sample-collection"
              element={<HomeSampleCollection />}
            />
            <Route path="/book-test" element={<BookTest />} />
            <Route path="/book-test/view-cart" element={<ViewCart />} />
            <Route
              path="/book-test/manage-bookings"
              element={<ManageBookings />}
            />
            <Route path="/packages" element={<TestPackages />} />
            <Route
              path="/packages/health-checkup"
              element={<HealthCheckupPackages />}
            />
            <Route
              path="/packages/specialized"
              element={<SpecializedPackages />}
            />
            <Route
              path="/packages/customized"
              element={<CustomizedPackages />}
            />
            <Route path="/dos" element={<DOS />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/media" element={<Media />} />
            <Route path="/locations" element={<Locations />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/contact/form" element={<ContactForm />} />
            <Route path="/contact/faqs" element={<FAQs />} />
            <Route path="/careers" element={<CurrentOpenings />} />
            <Route path="/login-signup" element={<LoginSignup />} />
            <Route path="/addToCart" element={<DepartmentCards />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/order" element={<OrderPage />} />
            <Route
              path="/order-confirmation"
              element={<OrderConfirmationPage />}
            />
          </Routes>
        </Suspense>

        {/* Shared Footer */}
        <Footer />
      </>
    </CartProvider>
  );
};

export default App;
