import React from "react";
import { Box, Typography, Container } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledFooter = styled(Box)(({ theme }) => ({
  backgroundColor: "#16423C",
  color: "#FFFFFF",
  padding: theme.spacing(3, 0),
  position: "relative",
  overflow: "hidden",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "2px",
    background: "linear-gradient(90deg, #15B392, #10375C)",
  },
}));

const WaveBox = styled(Box)({
  position: "absolute",
  bottom: 0,
  left: 0,
  width: "100%",
  height: "50px",
  background:
    "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'%3E%3Cpath fill='%2315B392' fill-opacity='0.2' d='M0,128L48,138.7C96,149,192,171,288,170.7C384,171,480,149,576,149.3C672,149,768,171,864,176C960,181,1056,171,1152,149.3C1248,128,1344,96,1392,80L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z'%3E%3C/path%3E%3C/svg%3E\")",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
});

const Footer = () => {
  return (
    <StyledFooter component="footer">
      <Container maxWidth="lg">
        <Typography
          variant="body2"
          align="center"
          sx={{
            fontWeight: 300,
            letterSpacing: 1,
            position: "relative",
            zIndex: 1,
          }}
        >
          &copy; {new Date().getFullYear()} Amritaa Diagnostic. All rights
          reserved.
        </Typography>
      </Container>
      <WaveBox />
    </StyledFooter>
  );
};

export default Footer;
