import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import WorkIcon from "@mui/icons-material/Work";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import SummarizeIcon from "@mui/icons-material/Summarize";

export const menuData = [
  { title: "Home", icon: HomeIcon, submenu: [], path: "/" },
  {
    title: "About Us",
    icon: InfoIcon,
    submenu: [
      { title: "About", path: "/about/about" },
      { title: "Our Story", path: "/about/our-story" },
      { title: "Mission & Vision", path: "/about/mission-vision" },
      { title: "Accreditations", path: "/about/accreditations" },
    ],
  },
  {
    title: "Services",
    icon: MedicalServicesIcon,
    submenu: [
      { title: "Our Services", path: "/services/technology" },
      { title: "Diagnostics", path: "/services/diagnostics" },
      { title: "Health Checkups", path: "/services/health-checkups" },
      { title: "Home Collection", path: "/services/home-sample-collection" },
    ],
  },
  {
    title: "Book a Test",
    icon: BookOnlineIcon,
    submenu: [],
    path: "/book-test",
  },
  {
    title: "Packages",
    icon: LocalOfferIcon,
    submenu: [{ title: "Health Checkup", path: "/packages/health-checkup" }],
  },
  {
    title: "DOS",
    icon: MedicalInformationIcon,
    submenu: [],
    path: "/dos",
  },
  {
    title: "Reports",
    icon: SummarizeIcon,
    submenu: [],
    path: "/reports",
  },
  {
    title: "Media",
    icon: SummarizeIcon,
    submenu: [],
    path: "/media",
  },
  {
    title: "Contact",
    icon: ContactSupportIcon,
    submenu: [
      { title: "Contact Form", path: "/contact/form" },
      { title: "FAQs", path: "/contact/faqs" },
    ],
  },
  {
    title: "Careers",
    icon: WorkIcon,
    submenu: [],
    path: "careers",
  },
];

export const locations = ["Vijaywada", "Rajmundry", "Eluru", "Guntur"];
